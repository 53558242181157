import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import styled from "@emotion/styled"
import WavyLink from "../fragments/wavy-link"

const FeaturedProjects = () => {
  const data = useStaticQuery(graphql`
    query FeaturedProjects {
      craft {
        entries(section: "work", limit: 4, search: "yes") {
          title
          slug
          ... on Craft_work_work_Entry {
            featuredProject
            projectType
            image: projectThumbnail {
              title
              url(width: 1000, quality: 100, immediately: true)
            }
            imageFile {
              childImageSharp {
                fluid(maxWidth: 800, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
  `)

  const projects = data.craft.entries

  const ProjectList = styled.section`
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: repeat(5, 1fr);
    gap: 2rem;
    margin-bottom: 4rem;
    padding-top: 5rem;
    max-width: 98rem;

    @media (min-width: 46rem) {
      grid-template-columns: repeat(7, 1fr);
      gap: 6rem 10rem;
      /* margin-top: 4rem; */
      padding-top: 6.5rem;
    }

    span,
    p {
      grid-column-end: span 7;
    }
  `

  const Project = styled.div`
    grid-column-end: span 7;
    margin-bottom: 2rem;

    @media (min-width: 46rem) {
      grid-column-end: span 4;
      align-self: center;

      &:nth-of-type(2),
      &:nth-of-type(3) {
        grid-column-end: span 3;

        img {
          height: 22rem;
        }
      }

      &:hover {
        div:before {
          box-shadow: inset 0px 0px 0px 0.5rem rgba(255, 255, 255, 1);
          transition: all 0.22s ease-in;
        }

        h3 a:after {
          animation-play-state: running;
        }

        p {
          transition: all 0.22s ease-in;
          transform: translateY(-1rem);
          opacity: 1;
        }
      }
    }
  `

  const ProjectImage = styled.div`
    margin-bottom: 1rem;

    @media (min-width: 46rem) {
      position: relative;

      &:before {
        position: absolute;
        box-shadow: inset 0px 0px 0px 0rem rgba(255, 255, 255, 1);
        transition: all 0.44s ease-out;
        content: "";
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        display: block;
        z-index: 1;
        pointer-events: none;
      }
    }
  `

  const ProjectTitle = styled.h3`
    /* font-family: "IBMPlexSans", sans-serif; */
    font-size: 1.7rem;
    margin: 0;

    a {
      font-size: 2.45rem;
      line-height: 2.45rem !important;
      padding-bottom: 0 !important;
    }

    @media (min-width: 46rem) {
      padding-left: 0.65rem;
    }
  `

  const ProjectCaption = styled.p`
    font-size: 0.88rem;
    color: ${props => props.theme.grays.mid};
    padding-left: 0.15rem;
    margin-bottom: 0rem;

    @media (max-width: 46rem) {
      margin-top: 0.75rem;
    }

    @media (min-width: 46rem) {
      transition: all 0.44s ease-out;
      font-size: 1rem;
      margin: 2rem 0 -1rem;
      opacity: 0;
      will-change: transform, opacity;
      padding-left: 0.65rem;
    }
  `

  return (
    <ProjectList id="projects">
      {projects.map((project, i) => (
        <Project
          key={i}
          data-sal="slide-up"
          data-sal-delay={(i + 0) * 150}
          data-sal-duration="150"
          data-sal-easing="ease-in-sine"
          className="project-entry"
        >
          <ProjectImage>
            <Link to={`/work/${project.slug}`} title={project.title}>
              <Img
                fluid={project.imageFile[0].childImageSharp.fluid}
                alt={project.title}
                title={project.title}
              />
            </Link>
          </ProjectImage>

          <ProjectTitle>
            <WavyLink
              href={`/work/${project.slug}`}
              title={project.title}
              text={project.title}
              textColor="blackText"
              lineColor="yellowLine blueLineHover"
            />
          </ProjectTitle>

          <ProjectCaption>{project.projectType}</ProjectCaption>
        </Project>
      ))}
    </ProjectList>
  )
}

export default FeaturedProjects

import React from "react"
import WavyLink from "../fragments/wavy-link"
import styled from "@emotion/styled"

const ContactCTAContainer = styled.section`
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  display: grid;
  grid-auto-flow: dense;
  grid-template-columns: repeat(6, 1fr);
  padding: 4rem 1.4rem;
  gap: 1.25rem 2rem;

  @media (min-width: 46rem) {
    background-size: 90%;
    background-position: center right;
    margin-top: 9rem;
    margin-bottom: 3rem;
    padding-top: 10rem;
    padding-bottom: 10rem;
    gap: 1.5rem 3rem;
  }

  h4 {
    grid-column-end: span 6;
    margin: 0;
    text-align: center;
    font-size: 1.85rem;

    @media (min-width: 46rem) {
      /* grid-column-start: 2; */
      /* grid-column-end: span 4; */
      font-size: 4rem;
    }

    span {
      color: ${props => props.theme.primaryBlue};
    }
  }

  p {
    grid-column-end: span 6;
    margin: 0;
    text-align: center;
    color: ${props => props.theme.grays.dark};

    @media (max-width: 46rem) {
      font-size: 0.8rem;
    }

    @media (min-width: 46rem) {
      grid-column-start: 2;
      grid-column-end: span 4;
    }

    a {
      font-size: 1.2rem;
    }
  }
`

const ContactCta = () => {
  return (
    <ContactCTAContainer className="cta">
      <p>Have you already got a project in mind?</p>
      <h4>
        Let's make <span>something awesome</span> together!
      </h4>
      <p>
        <WavyLink
          href={"/contact"}
          title="Contact"
          text="Say hello!"
          textColor="blackText"
          lineColor="yellowLine blueLineHover"
        />
      </p>
    </ContactCTAContainer>
  )
}

export default ContactCta

import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo/seo"
import Layout from "../components/layout"
import HomeIntro from "../components/home/intro"
import FeaturedProjects from "../components/home/featured-projects"
import Expertise from "../components/home/expertise.js"
import LatestNote from "../components/home/latest-note.js"
import ContactCta from "../components/site/contact-cta"

function IndexPage({ data }) {
  const seoImage =
    data.site.siteMetadata.siteUrl +
    data.craft.entries[0].imageFile[0].childImageSharp.fixed.src

  return (
    <Layout>
      <SEO
        title={data.craft.entries[0].seoTitle}
        description={data.craft.entries[0].seoDescription}
        image={seoImage}
        pathname={``}
        website
        homepage
      />
      <HomeIntro />
      <FeaturedProjects />
      <Expertise />
      <LatestNote />
      <ContactCta />
    </Layout>
  )
}

export const homeSEO = graphql`
  query HomeSEO {
    site {
      siteMetadata {
        siteUrl
      }
    }
    craft {
      entries(section: "home") {
        dateCreated
        dateUpdated
        ... on Craft_home_home_Entry {
          seoTitle
          seoDescription
          image: seoImage {
            title
            url(width: 720, height: 475, quality: 100, immediately: true)
          }
          imageFile {
            childImageSharp {
              fixed(width: 600, quality: 90) {
                src
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage

import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import styled from "@emotion/styled"

const Expertise = () => {
  const data = useStaticQuery(graphql`
    query HomeExpertise {
      craft {
        entries(section: "about") {
          ... on Craft_about_about_Entry {
            expertise {
              ... on Craft_expertise_skillList_BlockType {
                skillListTitle
                skills {
                  title
                }
              }
            }
          }
        }
      }
    }
  `)

  const skills = data.craft.entries[0].expertise

  const ExpertiseWrapper = styled.section`
    background-color: ${props => props.theme.grays.xxlight};
    max-width: none;
    width: 100%;
    display: block;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;

    @media (min-width: 46rem) {
      margin-top: 4rem;
      margin-bottom: 4rem;
      padding-top: 9rem;
      padding-bottom: 9rem;

      &:hover {
        h3:before {
          clip-path: polygon(50% 0%, 100% 100%, 50% 100%, 0 100%);
          transition: clip-path 0.55s ease-in-out;
        }
      }
    }
  `

  const ExpertiseContainer = styled.div`
    max-width: 88rem;
    margin: 0rem auto;
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: repeat(6, 1fr);
    gap: 0rem 2rem;

    h3 {
      font-size: 2.3rem;
      position: relative;
      z-index: 1;

      &:before {
        content: "";
        position: absolute;
        background-color: ${props => props.theme.primaryYellow};
        top: -3rem;
        left: -3rem;
        width: 10rem;
        height: 10rem;
        clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
        transition: clip-path 0.33s ease-out;
        z-index: -1;
      }
    }

    @media (min-width: 46rem) {
      margin: 4rem auto;
      padding-left: 1.4rem;
      padding-right: 1.4rem;
      gap: 6rem;

      h3 {
        font-size: 4.3rem;
      }
    }
  `

  const ExpIntro = styled.div`
    grid-column-end: span 6;

    @media (min-width: 46rem) {
      grid-column-end: span 3;
    }
  `
  const ExpList = styled.div`
    grid-column-end: span 6;
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: repeat(12, 1fr);
    gap: 0.5rem;

    @media (min-width: 46rem) {
      grid-column-end: span 3;
    }
  `

  const ExpertiseColumn = styled.div`
    grid-column-end: span 12;

    @media (min-width: 46rem) {
      grid-column-end: span 6;
      margin-bottom: 4rem;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    h4 {
      font-size: 1.3rem;
      margin-top: 1.5rem;

      &:after {
        content: ".";
        color: ${props => props.theme.primaryBlue};
      }
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        margin: 0;
        padding: 0;

        &:before {
          content: "—";
          margin-right: 0.55rem;
        }
      }
    }
  `

  return (
    <ExpertiseWrapper className="homeExp">
      <ExpertiseContainer>
        <ExpIntro>
          <h3>I like to make things work.</h3>
          <p>
            From website design and development projects whilst partnering with
            agencies to coopertively working with clients on ground-up website
            rebuilds, I'm happy to be involved at any stage of a project.
          </p>

          <p>
            One of my core skills is ensuring strong communication throughout a
            project whilst establishing the perfect solution and implementation
            of the final product.
          </p>

          <p>
            <Link to={`/about`} title="About me">
              Want to find out more?
            </Link>
          </p>
        </ExpIntro>
        <ExpList>
          {skills.map((skillRange, i) => (
            <ExpertiseColumn key={i}>
              <h4>{skillRange.skillListTitle}</h4>
              <ul>
                {skillRange.skills.map((skill, i) => {
                  return <li key={i}>{skill.title}</li>
                })}
              </ul>
            </ExpertiseColumn>
          ))}
        </ExpList>
      </ExpertiseContainer>
    </ExpertiseWrapper>
  )
}

export default Expertise

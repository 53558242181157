import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import styled from "@emotion/styled"
import WavyLink from "../fragments/wavy-link"

const LatestNote = () => {
  const data = useStaticQuery(graphql`
    query LatestNote {
      craft {
        entries(section: "notes", orderBy: "postDate DESC", limit: 2) {
          title
          slug
          ... on Craft_notes_notes_Entry {
            image: noteThumbnail {
              title
              url(width: 700, height: 600, quality: 100, immediately: true)
            }
            imageFile {
              childImageSharp {
                fluid(maxWidth: 700, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
  `)

  const NoteWrapper = styled.section`
    margin: 3rem auto 0;

    @media (min-width: 46rem) {
      margin: 7rem auto;
    }
  `

  const NoteContainer = styled.div`
    width: 100%;
    max-width: 88rem;
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: repeat(6, 1fr);
    gap: 0 2rem;
    margin: 0 auto;

    @media (min-width: 46rem) {
      grid-template-columns: repeat(12, 1fr);
      gap: 0 3rem;
      padding: 4rem 1.4rem 0;
    }
  `

  const Intro = styled.div`
    grid-column-end: span 6;
    grid-column-start: 1;
    position: relative;
    z-index: 1;
    grid-row: 1;
    align-self: center;

    @media (min-width: 46rem) {
      grid-column-start: 3;
      grid-column-end: span 7;
    }

    h4 {
      position: relative;
      z-index: 1;
      font-size: 2.25rem;
      line-height: 2.35rem;

      @media (min-width: 46rem) {
        font-size: 3.25rem;
        line-height: 3.35rem;
      }
    }

    p {
      margin: 0 0 2rem;

      @media (min-width: 46rem) {
        width: 60%;
      }
    }
  `

  const Blobs = styled.div`
    grid-column-end: span 6;
    grid-column-start: 1;
    position: relative;
    z-index: 0;
    grid-row: 1;

    @media (min-width: 46rem) {
      grid-column-end: span 5;
    }

    svg,
    img {
      position: relative;
      left: -30%;

      @media (max-width: 46rem) {
        left: -55%;
        transform: scale(1.2) rotate(21deg);
      }
    }
  `

  const Notes = styled.div`
    grid-column-end: span 6;
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: repeat(4, 1fr);
    gap: 3rem;
    margin-top: 2rem;

    @media (min-width: 46rem) {
      grid-column-start: 5;
      grid-column-end: span 8;
      gap: 4rem;
      margin-top: -6.5rem;
    }
  `

  const Note = styled.div`
    grid-column-end: span 4;

    @media (min-width: 46rem) {
      grid-column-end: span 2;
      transition: transform 0.44s cubic-bezier(0.51, 0.92, 0.24, 1.15);
      transform: translateY(0rem);
      will-change: transform;

      &:hover {
        transition: transform 0.44s cubic-bezier(0.51, 0.92, 0.24, 1.15);
        transform: translateY(-0.5rem);

        h5 {
          transition: transform 0.22s cubic-bezier(0.51, 0.92, 0.24, 1.15) 0.11s;
          transform: translateY(-0.25rem);

          a:after {
            animation-play-state: running;
            filter: invert(94%) sepia(65%) saturate(3194%) hue-rotate(328deg)
              brightness(104%) contrast(96%);
          }
        }
      }
    }

    .gatsby-image-wrapper {
      margin-bottom: 1rem;
    }

    h5 {
      margin: 0;
      font-size: 1.55rem;
      transition: transform 0.44s cubic-bezier(0.51, 0.92, 0.24, 1.15);
      transform: translateY(0rem);

      a {
        text-decoration: none;
      }
    }
  `

  return (
    <NoteWrapper>
      <NoteContainer>
        <Intro>
          <h4>Thoughts on things...</h4>
          <p>
            🡒 Rambling words and general musings about various stuff and things
            going on in my life
          </p>
          <WavyLink
            href={"/notes"}
            title="Notes"
            text="More Notes"
            textColor="blackText"
            lineColor="blueLine yellowLineHover"
          />
        </Intro>
        <Blobs>
          {/* <img src={"/svg/thoughts-blobs.svg"} alt="Blobs" className="blobs" /> */}
        </Blobs>
        <Notes>
          {data.craft.entries.map((note, i) => (
            <Note key={i}>
              <Link to={`/notes/${note.slug}`} title={note.title}>
                <Img
                  fluid={note.imageFile[0].childImageSharp.fluid}
                  alt={note.title}
                  title={note.title}
                />
              </Link>
              <h5>
                <WavyLink
                  href={`/notes/${note.slug}`}
                  title={note.title}
                  text={note.title}
                  textColor="blackText"
                  lineColor="whiteLine blueLineHover"
                />
              </h5>
            </Note>
          ))}
        </Notes>
      </NoteContainer>
    </NoteWrapper>
  )
}

export default LatestNote

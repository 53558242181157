import React from "react"
import { Link } from "gatsby"

import styled from "@emotion/styled"

const WiggleLink = styled.span`
  a {
    font-weight: 600;
    line-height: 0;
    padding: 0 0.15rem 0.85rem;
    text-decoration: none;
    background-color: transparent;
    position: relative;
    display: inline-block;
    z-index: 1;

    &:after {
      animation: move 9s linear infinite;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 19.3 6' width='100%25' height='100%25'%3E%3Cstyle type='text/css'%3E%0A.st0%7Bfill:black;%7D%0A%3C/style%3E%3Cpath class='st0' d='M-5.5,3.8C-4.2,4.8-2.8,6,0,6s4.2-1.2,5.5-2.2C6.6,2.8,7.6,2,9.7,2s3,0.8,4.2,1.8c1.3,1,2.7,2.2,5.5,2.2 s4.2-1.2,5.5-2.2l-1.4-0.9c-1.1,1-2.1,1.8-4.2,1.8s-3-0.8-4.2-1.8c-1.2-1-2.7-2.2-5.4-2.2c-2.8,0-4.2,1.2-5.5,2.2 C3,3.9,2.1,4.7,0,4.7s-3-0.8-4.2-1.8L-5.5,3.8z'%3E%3C/path%3E%3C/svg%3E");
      background-repeat: repeat-x;
      background-size: 3rem;
      content: "";
      position: absolute;
      left: 0;
      bottom: -0.75rem;
      width: 100%;
      height: 1rem;
      z-index: -1;

      @media (max-width: 46rem) {
        background-size: 2.07rem;
        bottom: -0.55rem;
      }
    }

    @media (max-width: 46rem) {
      &:after {
        animation-play-state: running;
      }
    }

    @media (min-width: 46.01rem) {
      &:after {
        animation-play-state: paused;
      }

      &:hover {
        &:after {
          animation-play-state: running;
        }
      }
    }

    &.blackText {
      color: ${props => props.theme.textColor};
    }

    &.blueText {
      color: ${props => props.theme.primaryBlue};
    }

    &.whiteText {
      color: ${props => props.theme.default.white};
    }

    &.yellowText {
      color: ${props => props.theme.primaryYellow};
    }

    /* White Text/Lines */
    &.whiteLine {
      &:after {
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg)
          brightness(102%) contrast(102%);
      }
    }

    &.whiteLineHover {
      &:hover {
        &:after {
          filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg)
            brightness(102%) contrast(102%);
        }
      }
    }

    /* Blue Text/Lines */
    &.blueLine {
      &:after {
        filter: invert(11%) sepia(100%) saturate(6251%) hue-rotate(245deg)
          brightness(80%) contrast(142%);
      }
    }

    &.blueLineHover {
      &:hover {
        &:after {
          filter: invert(11%) sepia(100%) saturate(6251%) hue-rotate(245deg)
            brightness(80%) contrast(142%);
        }
      }
    }

    /* Yellow Text/Lines */
    &.yellowLine {
      &:after {
        filter: invert(69%) sepia(96%) saturate(934%) hue-rotate(11deg)
          brightness(109%) contrast(107%);
      }
    }

    &.yellowLineHover {
      &:hover {
        &:after {
          filter: invert(69%) sepia(96%) saturate(934%) hue-rotate(11deg)
            brightness(109%) contrast(107%);
        }
      }
    }

    @media (min-width: 46rem) {
      animation-play-state: paused;

      &:hover {
        animation-play-state: running;
      }
    }
  }
`

function WavyLink(props) {
  const wiggleClass = props.textColor + " " + props.lineColor

  return (
    <WiggleLink>
      <Link to={props.href} title={props.title} className={wiggleClass}>
        <span>{props.text}</span>
      </Link>
    </WiggleLink>
  )
}

export default WavyLink

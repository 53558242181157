import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import styled from "@emotion/styled"

const Availability = props => {
  const data = useStaticQuery(graphql`
    query Availability {
      craft {
        globalSets(handle: "availability") {
          ... on Craft_availability_GlobalSet {
            availabilityContent
          }
        }
      }
    }
  `)

  const availabilityContent = data.craft.globalSets[0].availabilityContent

  const AvailabilityContainer = styled.div`
    grid-column-end: span 3;
    text-align: right;
    margin: -1rem 0rem 0 0;

    @media (min-width: 46rem) {
      margin: -4rem 2rem 0 0;
    }

    @media (max-width: 46rem) {
      overflow: hidden;
    }

    svg {
      transform-origin: center center;
      animation: rotation 9s infinite linear;

      textPath {
        fill: ${props => props.theme.primaryBlue};
        font-weight: 400;

        @media (min-width: 46rem) {
          fill: ${props => props.theme.default.black};
        }
      }

      &:hover {
        textPath {
          fill: ${props => props.theme.primaryBlue};
        }
      }
    }
  `

  return (
    <AvailabilityContainer className={`${props.loc}-circ`}>
      <Link to={`/contact`} title="Contact me">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="200px"
          width="200px"
          style={{
            margin: `${props.margin}`,
          }}
        >
          <path
            id="myTextPath"
            d="M 62,0 A 62,62 0 0 1 -62,0 A 62,62 0 0 1 62,0"
            transform="translate(100,100)"
            fill="none"
            stroke-width="25"
          />
          <text>
            <textPath href="#myTextPath">{availabilityContent}</textPath>
          </text>
        </svg>
      </Link>
    </AvailabilityContainer>
  )
}

export default Availability

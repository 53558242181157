import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "react-scroll"
import styled from "@emotion/styled"
import Availability from "../../components/contact/availability"

const HomeIntro = () => {
  const data = useStaticQuery(graphql`
    query HomeIntro {
      craft {
        entries(section: "home") {
          ... on Craft_home_home_Entry {
            homeIntroTop
            homeIntroMain
          }
        }
      }
    }
  `)

  const hpintro = data.craft.entries[0]

  const HomeWrapper = styled.div``

  const HomeContainer = styled.section`
    align-items: center;
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: repeat(12, 1fr);
    gap: 1rem;
    z-index: 0;
    height: 80vh;
    padding-top: 5rem;

    @media (max-width: 46rem) {
      overflow-x: hidden;
    }

    @media (min-width: 46rem) {
      gap: 4rem 1.4rem;
      margin-bottom: 15vh;
      padding-bottom: 5rem;
      height: 100vh;
    }
  `

  const IntroLeft = styled.div`
    grid-column-end: span 12;

    @media (max-width: 46rem) {
      align-self: end;
    }

    @media (min-width: 46rem) {
      grid-column-end: span 6;
    }

    h1 {
      font-size: 2.15rem;
      margin-bottom: 0.75rem;

      @media (min-width: 46rem) {
        font-size: 4.5rem;
        margin-bottom: 1rem;
      }
    }

    p {
      span {
        margin-right: 0.85rem;
        font-size: 1.75rem;
        position: relative;
        top: 0.35rem;
        animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
        animation-duration: 2.5s; /* Change to speed up or slow down */
        animation-iteration-count: infinite; /* Never stop waving :) */
        transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
        display: inline-block;
      }
    }
  `

  const IntroRight = styled.div`
    grid-column-end: span 12;

    @media (max-width: 46rem) {
      align-self: start;
    }

    @media (min-width: 46rem) {
      grid-column-end: span 5;
      grid-column-start: 8;
      align-self: end;
      padding-bottom: 4rem;
    }

    p {
      font-size: 0.98rem;
      line-height: 1.35rem;

      a {
        cursor: pointer;
      }

      &:first-of-type {
        &:before {
          content: "🡒";
          display: inline-block;
          margin-right: 0.55rem;
        }
      }

      @media (min-width: 46rem) {
        font-size: 1.1rem;
        line-height: 1.55rem;

        a {
          color: inherit;

          &:hover {
            color: ${props => props.theme.primaryBlue};
          }
        }
      }
    }

    .home-circ {
      @media (max-width: 46rem) {
        /* position: absolute;
        right: 1rem;
        bottom: 2%;
        z-index: 1; */
        display: none;
      }
    }
  `

  return (
    <HomeWrapper>
      <HomeContainer>
        <IntroLeft>
          <p>
            <span className="wave" role="img" aria-label="waving hand">
              👋
            </span>
            Hello there,
          </p>
          <h1
            data-sal="flip-up"
            data-sal-delay="0"
            data-sal-duration="300"
            data-sal-easing="ease-in-out"
          >
            I'm Dave, a {hpintro.homeIntroTop}...
          </h1>
        </IntroLeft>
        <IntroRight>
          <p
            data-sal="slide-up"
            data-sal-delay="75"
            data-sal-duration="450"
            data-sal-easing="ease-in-out"
            dangerouslySetInnerHTML={{ __html: hpintro.homeIntroMain }}
          />
          <p
            data-sal="fade-in"
            data-sal-delay="75"
            data-sal-duration="450"
            data-sal-easing="ease-in-out"
          >
            <Link to="projects" spy={true} smooth={true} duration={500}>
              View Selected Projects
            </Link>
          </p>

          <Availability loc="home" margin="1rem 0 0" />
        </IntroRight>
      </HomeContainer>
    </HomeWrapper>
  )
}

export default HomeIntro
